import { Components, Theme } from '@mui/material';

const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  defaultProps: {
    slotProps: {
      actions: {
        // Mui defaults to <IconButton color="inherit" ... />, we want to change that
        firstButton: { color: 'secondary' },
        lastButton: { color: 'secondary' },
        nextButton: { color: 'secondary' },
        previousButton: { color: 'secondary' },
      },
    },
  },
  styleOverrides: {},
};

export default MuiTablePagination;
