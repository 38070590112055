import { Link as InternalLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import invariant from 'invariant';
import GOBSDream from 'src/resources/GOBSDream.png';
import GOBSDream2x from 'src/resources/GOBSDream@2x.png';
import GOBSDream3x from 'src/resources/GOBSDream@3x.png';
import Strikethrough from 'src/resources/Strikethrough.svg';
import { Route } from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';

export const Stroke = styled(Strikethrough)(({ fill }: { fill: string }) => ({
  fill,
  position: 'absolute',
  top: '40%',
}));

type DomainNotFoundProps = {
  /**
   * image: for use in srcSet - typically design will give us a set of 3 images for responsiveness
   *    - default: default size,
   *    - md: @2x image size
   *    - lg: @3x image size
   *  */
  image?: { default: string; md: string; lg: string };
  secondaryText: React.ReactNode;
  fill?: string;
  /**
   * If no domain provided, falls back to "cant load your request." Please add
   * domains as needed here.
   */
  domain?:
    | 'blueprint'
    | 'connections'
    | 'models'
    | 'project'
    | 'projects'
    | 'request'
    | 'workflows'
    | 'workflow'
    | 'workflow runs';
  action?: { text: string; redirect: Route };
  contentStyles?: Record<string, unknown>;
};

const DomainNotFound = ({
  image = {
    default: GOBSDream,
    md: GOBSDream2x,
    lg: GOBSDream3x,
  },
  secondaryText,
  fill,
  domain,
  action,
  contentStyles,
}: DomainNotFoundProps) => {
  invariant(
    'md' in image || 'lg' in image,
    'You must supply md and lg options for responsive images'
  );
  const theme = useTheme();
  const { compileLocation } = useConsoleRoute();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      wrap="nowrap"
    >
      <Card
        sx={theme => ({
          width: '100%',
          borderRadius: theme.shape.radii.large,
        })}
      >
        <CardMedia
          component="img"
          image={image.default}
          srcSet={`${image.default} 1x, ${image.md} 2x, ${image.lg} 3x`}
          sx={{ height: '50%' }}
          alt="Gobs the cat floating in the air."
        />
        <CardContent
          sx={{
            padding: '0',
            marginTop: '-6%',
            '&:last-child': {
              paddingBottom: 8,
            },
            ...contentStyles,
          }}
        >
          <Typography
            variant="display2"
            sx={{
              color: 'inherit',
              marginBottom: 2,
            }}
          >
            <>
              Sorry, we{' '}
              <Box aria-hidden="true" display="inline" position="relative">
                <Stroke fill={fill || theme.palette.warning.main} />
                cat{' '}
              </Box>
              {domain
                ? `can't find your ${domain}.`
                : "can't load your request."}
            </>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={theme => ({
              mb: 8,
              color: theme.palette.text.secondary,
            })}
          >
            {secondaryText}
          </Typography>

          {action && (
            <CardActions
              sx={{
                borderTop: 0,
                justifyContent: 'center',
                padding: 0,
                backgroundColor: 'inherit',
              }}
            >
              <Button
                variant="contained"
                size="large"
                component={InternalLink}
                to={compileLocation(action.redirect)}
              >
                {action.text}
              </Button>
            </CardActions>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DomainNotFound;
