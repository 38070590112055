import { toast } from 'react-hot-toast';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { parse } from 'yaml';
import {
  LEGACY_MODEL_TYPE,
  MODEL_TYPE,
  reverseMapModelTypes,
} from 'common/constants/modelType';
import { getConfigTemplateNameAndCategoryFromFilePath } from 'common/utils/config';
import { fetchRemote, serializableError } from 'common/utils/fetch';
import { GRETEL_BLUEPRINTS_URL } from 'src/routes';
import { Store } from 'src/store.types';

type Template = { json: Record<string, unknown>; yaml: string };
export type ModelConfigManifest = Record<string, Record<string, string>>;
export type ModelConfigTemplates = Record<string, Record<string, Template>>;

export type ModelConfigTemplateState = {
  manifest?: ModelConfigManifest;
  templates: ModelConfigTemplates;
};

/**
 * Slice for fetching the model manifest and coordinating template downloads from Gretel Blueprints.
 */

// Load the manifest from gretel-blueprints into the store
const loadManifest = createAsyncThunk(
  'modelTemplates/loadManifest',
  async (_, api) => {
    try {
      return fetchRemote(GRETEL_BLUEPRINTS_URL, {
        accept: 'application/json',
      })
        .then<{ templates: string[] }>(response => response.json())
        .then(({ templates }: { templates: string[] }) =>
          templates.reduce((manifest, file) => {
            const { modelType, name } =
              getConfigTemplateNameAndCategoryFromFilePath(file);
            if (!(modelType in manifest)) {
              manifest[modelType] = {};
            }
            manifest[modelType][name] = file;
            return manifest;
          }, {} as ModelConfigManifest)
        );
    } catch (e) {
      // Reject and notify user of failure. The rejection itself
      // isn't handled since we can toast directly.
      toast.error('Error fetching model config, please reload and try again');
      return api.rejectWithValue(serializableError(e));
    }
  }
);

// Load a template from the manifest into the store
const loadTemplate = createAsyncThunk(
  'modelTemplates/loadTemplate',
  async (
    params: { category: MODEL_TYPE | LEGACY_MODEL_TYPE; name: string },
    api
  ) => {
    try {
      const { category, name } = params;
      const { modelTemplates } = api.getState() as Store;

      // If the requested template already exists, return that instead.
      // This will result in the reducer being called with the same data,
      // but that's better than fetching again. -md
      if (modelTemplates.templates[category]?.[name]) {
        return {
          category,
          name,
          yaml: modelTemplates.templates[category][name].yaml,
        };
      }

      let { manifest } = modelTemplates;

      // If the manifest has not been loaded yet, do that first and continue.
      // I'm not entirely sure how we could end up in this state, but I feel
      // like we should handle it regardless. -md
      if (!manifest) {
        manifest = await api.dispatch(loadManifest()).unwrap();
      }

      return fetch(`${GRETEL_BLUEPRINTS_URL}/${manifest[category][name]}`)
        .then(res => res.text())
        .then(yaml => ({ category, name, yaml }));
    } catch (e) {
      // Reject and notify user of failure. The rejection itself
      // isn't handled since we can toast directly.
      toast.error('Error getting model template');
      return api.rejectWithValue(serializableError(e));
    }
  }
);

export const modelTemplatesSlice = createSlice({
  name: 'modelTemplates',
  initialState: { templates: {} } as ModelConfigTemplateState,
  reducers: {
    reset: () => ({ templates: {} }),
  },
  extraReducers: builder => {
    builder.addCase(loadManifest.fulfilled, (state, action) => {
      state.manifest = action.payload;
    });

    builder.addCase(loadTemplate.fulfilled, (state, action) => {
      const { category, name, yaml } = action.payload;
      if (!state.templates[category]) {
        state.templates[category] = {};
      }
      state.templates[category][name] = { yaml, json: parse(yaml) };
    });
  },
});

const selectModelTemplate = createSelector(
  (state: Store) => state.modelTemplates,
  (_: Store, model?: string) => {
    if (!model) {
      return;
    }
    const [rawCategory, name] = model.split('/');
    const category = reverseMapModelTypes(rawCategory);
    return { category, name };
  },
  ({ templates }, model) => model && templates[model.category][model.name]
);

export const modelTemplateActions = { loadManifest, loadTemplate };
export const modelTemplateSelectors = { selectModelTemplate };
