import * as React from 'react';
import { AppBar, Box, IconButton, Link, Toolbar, Tooltip } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { DOCUMENTATION_HUB } from 'common/constants/urls';
import { useDarkMode, useSelectServerlessTenant } from 'common/featureFlags';
import { Toaster } from 'common/Toasts';
import {
  AnnouncementSpeaker as Bullhorn,
  ChevronRight,
  Document as BookOpen,
  Help as LifeBuoy,
} from 'icons/figma';
import { openZenDeskWidget } from 'utils/zenDesk';
import { useThemeMode } from '../Theming';
import BillingBanner from './BillingBanner';
import { Logo } from './Logo';
import { ThemeMenu } from './ThemeMenu';
import UserMenu from './UserMenu';
import { UserTenantBadge } from './UserTenantBadge';

interface AppToolbarProps {
  limited?: boolean;
}

const FaintChevron = styled(ChevronRight)`
  opacity: 0.2;
`;

const AppToolbar = ({ limited = false }: AppToolbarProps) => {
  const { mode } = useThemeMode();
  const { value: isDarkModeEnabled, loading: isLoadingDarkMode } =
    useDarkMode();
  const { value: canSelectServerlessTenant } = useSelectServerlessTenant();
  return (
    <AppBar
      position="fixed"
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
      })}
    >
      <Toolbar disableGutters variant="dense">
        <Box flexGrow={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Logo
              sx={{
                color: mode === 'light' ? 'primary.main' : 'common.white',
                mr: 2,
              }}
            />
            {canSelectServerlessTenant && (
              <>
                <FaintChevron />
                <UserTenantBadge />
              </>
            )}
          </Stack>
        </Box>
        {!limited && (
          <>
            {!isLoadingDarkMode && isDarkModeEnabled && (
              <Tooltip variant="dark" title="Theme settings" placement="bottom">
                <span>
                  <ThemeMenu />
                </span>
              </Tooltip>
            )}
            <Tooltip variant="dark" title="Documentation" placement="bottom">
              <IconButton
                component={Link}
                aria-label="Documentation"
                aria-controls="updateMeBB"
                aria-haspopup="true"
                href={DOCUMENTATION_HUB}
              >
                <BookOpen />
              </IconButton>
            </Tooltip>
            <Tooltip variant="dark" title="Get Help" placement="bottom">
              <IconButton
                component={Link}
                aria-label="Get Help"
                aria-controls="notifyMeBB"
                aria-haspopup="true"
                onClick={openZenDeskWidget}
              >
                <LifeBuoy />
              </IconButton>
            </Tooltip>
            <Tooltip variant="dark" title="Product Updates" placement="bottom">
              <IconButton
                id="beamerButton"
                component={Link}
                aria-label="Product Updates"
                aria-controls="notifyMeBB"
                aria-haspopup="true"
              >
                <Bullhorn />
              </IconButton>
            </Tooltip>
          </>
        )}
        <UserMenu limited={limited} />
      </Toolbar>
      <BillingBanner />
      <Toaster />
    </AppBar>
  );
};

export default AppToolbar;
